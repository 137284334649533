








































import {
  computed,
  defineComponent, ref, useAsync, useContext
} from '@nuxtjs/composition-api';
import Accordion from "~/components/Palmers/BaseComponents/Accordion/Accordion.vue";
import loadFaqData from "~/customQueries/queries/loadFaqData.gql";
import loadPositiveFaqData from "~/customQueries/queries/loadPositiveFaqData.gql";
import rateFaqQuestion from "~/customQueries/mutation/rateFaqQuestion.gql";
import {usePageBuilder, useUrl} from "~/composables/Palmers";
import PageBuilder from "~/components/Palmers/PageBuilder/PageBuilder.vue";
export default defineComponent({
  name: 'Faq',
  components: {
    Accordion,
    PageBuilder
  },

  props: {
    showLink: {
      type: Boolean,
      default: false
    },
    pageSize: {
      type: Number,
      default: undefined
    },
    rating: {
      type: Boolean,
      default: false
    }
  },

  setup(_props) {
    const {isPageBuilder} = usePageBuilder();
    const {getPageUrl} = useUrl();
    const loaded = ref(false);
    const { $vsf } = useContext();
    const faqData = ref({
      items: []
    })

    const query = loadFaqData; // ToDo - test and implement loadPositiveFaqData query.

    const queryVariables = typeof _props.pageSize !== "undefined"
      ? {pageSize: _props.pageSize}
      : {};

    useAsync(async () => {
      //@ts-ignore
      const {searchAmFaqQuestions} = (await $vsf.$magento.api.customQuery({
        query: query,
        queryVariables: queryVariables
      })).data;

      faqData.value = searchAmFaqQuestions || {items: []};

      loaded.value = true;
    });

    const rateQuestion = async function ($questionId) {
      const variables = { questionId: $questionId };
      const { data, errors } = await $vsf.$magento.api.customMutation({
        mutation: rateFaqQuestion,
        mutationVariables: { ...variables }
      });

      return { data, errors };
    };

    return {
      faqData,
      rateQuestion,
      getPageUrl,
      isPageBuilder
    };
  },
});

