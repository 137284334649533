

























import {
  defineComponent, ref, useContext, useFetch,
} from '@nuxtjs/composition-api';
import loadSinglePost from "~/customQueries/queries/loadSinglePost.gql";
import PictureItem from "~/components/Palmers/BaseComponents/Picture/PictureItem.vue";
import Newsletter from "~/components/Palmers/Newsletter.vue";
import CategoryPagination from "~/components/Palmers/Category/CategoryPagination/CategoryPagination.vue";
import {usePageStore} from "~/stores/page";
import {useGtm, usePageBuilder, useUrl} from "~/composables/Palmers";
import PageBuilder from "~/components/Palmers/PageBuilder/PageBuilder.vue";

export default defineComponent({
  name: 'PalmersStorySingle',
  components: {
    CategoryPagination,
    PictureItem,
    Newsletter,
    PageBuilder
  },
  transition: 'fade',
  setup() {
    const {isPageBuilder} = usePageBuilder();
    const {getMediaUrl} = useUrl();
    const {$vsf} = useContext();
    const {routeData} = usePageStore();
    const {view} = useGtm();
    const loaded = ref(false);

    const blogPost = ref({})
    const post_id = routeData.post_id;
    const query = loadSinglePost;

    const fetchData = async () => {
      //@ts-ignore
      const {amBlogPost} = (await $vsf.$magento.api.customQuery({
        query: query,
        queryVariables: {
          id: post_id,
        }
      })).data;

      blogPost.value = amBlogPost || {item: []};

      loaded.value = true;
    };

    useFetch(fetchData);

    return {
      loaded,
      blogPost,
      getMediaUrl,
      content: routeData.full_content,
      updated: routeData.updated,
      isPageBuilder,
      id: routeData.post_id,
      title: routeData.title,
      image: routeData.post_thumbnail,
      created: routeData.created_at,
      view
    }
  },
  methods: {
    addSchema() {
      if (process.client && this.title) {
        let baseUrl = this.$i18n['__baseUrl'];

        if (baseUrl[baseUrl.length - 1] === '/') {
          baseUrl = baseUrl.slice(0, -1);
        }

        const images = [];

        //@ts-ignore
        images.push(this.getMediaUrl(this.image))

        return [
          {
            type: 'application/ld+json', json: {
              "@context": "https://schema.org",
              "@type": "NewsArticle",
              "headline": this.title,
              "image": images,
              "datePublished": this.created,
              "dateModified": this.updated,
              "author": [{
                "@type": "Person",
                "name": "Palmers",
                "url": baseUrl
              }]
            }
          }
        ]
      }

      return [];
    }
  },
  head() {
    return {
      //@ts-ignore
      script: this.addSchema()
    }
  },
  mounted() {
    //@ts-ignore
    this.view();
  }
});
