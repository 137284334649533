import {useContext} from "@nuxtjs/composition-api";

const useSeo = () => {
  const {i18n} = useContext();

  const getSeoData = (routeData) => {
    let baseUrl = i18n['__baseUrl'];

    if(baseUrl[baseUrl.length-1] === '/') {
      baseUrl = baseUrl.slice(0, -1);
    }

    const page = routeData;
    const links = [];
    const metas = [];
    const scripts = [];

    if(page?.['mw_canonical_url'] && page?.['mw_canonical_url']?.url) {
      links.push({
        hid: 'canonical',
        rel: "canonical",
        href: page['mw_canonical_url'].url.indexOf('http') > -1 ? page['mw_canonical_url'].url : `${baseUrl}/${page['mw_canonical_url'].url}`
      });
    }

    if(page?.['mw_hreflangs']?.items?.length) {
      for(let l = 0; l < page['mw_hreflangs'].items.length; l++) {
        const link = page['mw_hreflangs'].items[l];
        links.push({
          hid: `hreflang-${link.code}-${link.url}`,
          rel: "alternate",
          href: link.url.indexOf('http') > -1 ? link.url :`${baseUrl}/${link.url}`,
          hreflang: link.code
        });
      }
    }

    if(page?.['mw_seo_markup']) {
      const DOMParser = require('universal-dom-parser');
      const parser = new DOMParser();

      const innerHTML = `${page['mw_seo_markup']?.['social_markup']}
    ${page['mw_seo_markup']?.['rich_snippets']?.['seller']}
    ${page['mw_seo_markup']?.['rich_snippets']?.['website']}
    ${page['mw_seo_markup']?.['rich_snippets']?.['product']}
    ${page['mw_seo_markup']?.['rich_snippets']?.['webpage']}`;

      const doc = parser.parseFromString(innerHTML, 'text/html');

      const metaTags = doc.querySelectorAll('meta');
      const scriptsHtml = doc.querySelectorAll('script');
      metaTags.forEach((meta) => {
        if(meta.content) {
          const metaObj = {
            hid: meta.getAttribute('property'),
            property: meta.getAttribute('property'),
            content: meta.content
          }

          if(meta.name) {
            metaObj['name'] = meta.name;
            metaObj['hid'] = meta.name;
          }

          metas.push(metaObj);
        }
      });

      scriptsHtml.forEach((script) => {
        if(script.textContent) {
          const json = JSON.parse(script.textContent);
          scripts.push({
            hid: `schema-${json['@type']}`,
            type: script.type,
            json: JSON.parse(script.textContent),
            once: true,
            defer: true
          });
        }
      });
    }

    return {
      meta: metas,
      link: links,
      script: scripts
    }
  }

  return {
    getSeoData
  }
};

export default useSeo;
