export default `
  query searchAmFaqQuestions($pageSize: Int) {
    searchAmFaqQuestions(pageSize: $pageSize) {
      items {
        question_id
        title
        short_answer
        answer
        visibility
        status
        name
        email
        position
        url_key
        canonical_url
        nofollow
        noindex
        is_show_full_answer
        asked_from_store
        avg_rating
        avg_total
        category_ids
        store_ids
        tags
        customer_groups
        }
        page_info {
          current_page
          page_size
          total_pages
        }
      total_count
    }
  }
`;
