export default `
mutation rateAmFaqQuestion($questionId: Int!) {
    rateAmFaqQuestion(input: {
      question_id: $questionId,
      vote_type: VOTING,
      value: "1",
      is_revote: false,
      old_value: null
      })
    {
      message
    }
  }
 `;
