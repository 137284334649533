


























import {
  defineComponent, ref, watch,
} from '@nuxtjs/composition-api';
import {getMetaInfo} from '~/helpers/getMetaInfo';
import CategoryPageContent from "~/components/Palmers/Category/CategoryPageContent.vue";
import usePalmersCategory from "~/composables/Palmers/Category/usePalmersCategory";
import {useGtm, useMenu} from "~/composables/Palmers";

export default defineComponent({
  name: 'SubCategoryPage',
  components: {
    CategoryPageContent
  },
  transition: 'fade',
  setup() {
    const {setLoadedCategory, setLoadedProducts, view} = useGtm();
    const {setActiveCategory} = useMenu();
    const {
      isPriceLoaded,
      uiHelpers,
      toggleFilterSidebar,
      isCategoryGridView,
      changeToCategoryListView,
      changeToCategoryGridView,
      isFilterSidebarOpen,
      addItemToCart,
      addItemToWishlist,
      pagination,
      products,
      sortBy,
      cmsContent,
      activeCategoryName,
      routeData,
      doChangeItemsPerPage,
      onReloadProducts,
      goToPage,
      categoryPageContent,
      isInWishlist,
      isAuthenticated,
      itemsPerPage,
    } = usePalmersCategory();

    const loaded = ref(false);

    if(process.client) {
      setActiveCategory(routeData);
    }

    return {
      isPriceLoaded,
      ...uiHelpers,
      toggleFilterSidebar,
      isCategoryGridView,
      changeToCategoryListView,
      changeToCategoryGridView,
      isFilterSidebarOpen,
      addItemToCart,
      addItemToWishlist,
      pagination,
      products,
      sortBy,
      cmsContent,
      activeCategoryName,
      routeData,
      doChangeItemsPerPage,
      onReloadProducts,
      goToPage,
      categoryPageContent,
      isInWishlist,
      isAuthenticated,
      itemsPerPage,
      loaded,
      setLoadedCategory,
      setLoadedProducts,
      view
    }
  },
  head() {
    return getMetaInfo({
      ...this.routeData as object,
      mw_seo_category_data: this.products?.['mw_seo_category_data']
    });
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        if(!this.loaded && process.client) {
          //@ts-ignore
          this.setLoadedCategory({
            name: this.activeCategoryName
          });

          //@ts-ignore
          this.setLoadedProducts(this.products);

          //@ts-ignore
          this.view();
          this.loaded = true;
        }
      }, 0)
    })
  }
});
