






























































































import {
  ref,
  useContext,
  useRoute,
  defineComponent,
  useFetch, computed, onMounted
} from '@nuxtjs/composition-api';
import {merge} from 'lodash-es';
import {useCache} from '@vue-storefront/cache';
import {useProduct} from '~/modules/catalog/product/composables/useProduct';
import {getMetaInfo} from '~/helpers/getMetaInfo';
import {usePageStore} from '~/stores/page';
import {
  useUser,
  useWishlist,
  useApi,
  useCart,
  RelatedProductQuery,
  CmsBlock,
  useContent,
  useUpsellProducts
} from '~/composables';
import type {Product} from '~/modules/catalog/product/types';
import type {ProductDetailsQuery} from '~/modules/GraphQL/types';
import getProductPriceBySkuGql from '~/modules/catalog/product/queries/getProductPriceBySku.gql';
import RelatedProducts from '~/components/Palmers/Product/Related/RelatedProducts.vue';
import Newsletter from "~/components/Palmers/Newsletter.vue";
import Faq from "~/components/Palmers/Faq.vue";
import {getBreadcrumbs} from "~/modules/catalog/product/getters/productGetters";
import useRelatedProducts from "~/composables/Palmers/Product/useRelatedProducts";
import {useMenu, useUrl} from "~/composables/Palmers";
import useStoreWatcher from '~/composables/Palmers/useStoreWatcher';
import cmsGetters from "~/modules/palmers/cms/getters/cmsGetters";
import useBestSellesProducts from "~/composables/Palmers/useBestSellesProducts";
import {ignoreAnalyticsParams} from "~/helpers/ignoreAnalyticsParams";
import LoadWhenVisible from "~/components/utils/LoadWhenVisible.vue";

export default defineComponent({
  name: 'ProductPage',
  components: {
    RelatedProducts,
    Newsletter,
    Faq,
    LoadWhenVisible,
    UniversalProduct: () => import('~/components/Palmers/Product/product-types/UniversalProduct.vue'),
    ProductSkeleton: () => import('~/modules/catalog/product/components/ProductSkeleton.vue'),
    StaticBlock: () => import("~/components/Palmers/BaseComponents/Cms/StaticBlock.vue"),
  },
  transition: 'fade',
  setup() {
    const {routeData} = usePageStore();
    const {query} = useApi();
    const product = ref<Product | null>(null);
    const {addTags} = useCache();
    const route = useRoute();
    const {getProductDetails, loading} = useProduct();
    const staticBlocks = ref<CmsBlock[]>([]);
    const loaded = ref(false);
    const {setActiveCategory} = useMenu();

    //@ts-ignore
    const {error: nuxtError, $vsf} = useContext();
    const {getPageUrl} = useUrl();
    const {isGiftStore} = useStoreWatcher();
    const {isAuthenticated} = useUser();

    const {
      load: loadWishlist,
      addOrRemoveItem,
      isInWishlist
    } = useWishlist();

    const {
      search,
      loading: loadingRelated,
    } = useRelatedProducts();

    const {
      search: searchUpsell,
      loading: loadingUpsell,
    } = useUpsellProducts();

    const {
      search: searchBestSellers,
      loading: loadingBestSellers,
    } = useBestSellesProducts();

    const {
      addItem: addProductToCart,
      error: cartError,
      loading: isCartLoading,
      canAddToCart,
      isInCart: isProductInCart
    } = useCart();

    const getBaseSearchQuery = () => ({
      filter: {
        sku: {
          eq: routeData.sku,
        },
      },
      configurations: Object.entries(ignoreAnalyticsParams(route.value.query, [], product.value?.configurable_options))
        .filter((config) => config[0] !== 'wishlist')
        .map(
          (config) => config[1],
        ) as string[],
    });

    const getBreadcrumbsArray = () => {
      const productCategories = product.value?.categories || [];

      return [
        ...getBreadcrumbs(
          product.value,
          Array.isArray(productCategories) ? [...productCategories].pop() : null,
        ).map((breadcrumb) => ({...breadcrumb, link: getPageUrl(breadcrumb.link)}))
      ];
    }

    const breadcrumbs = ref();

    const fetchProductBaseData = async (searchQuery = getBaseSearchQuery()) => {
      if(routeData?.status === 2) nuxtError({statusCode: 404});

      if (routeData?.stock_status !== 'OUT_OF_STOCK') {
        const [result, reviewsData, labelsData] = await Promise.all([
          getProductDetails({
            ...searchQuery,
          }),
          //@ts-ignore
          $vsf.$magento.api.getProductDetailsReviews({
            productId: routeData.id,
            url: route.value.fullPath
          }),

          //@ts-ignore
          $vsf.$magento.api.getProductLabels({
            ...searchQuery,
          })
        ]);

        if (!result?.items || !result?.items.length) nuxtError({statusCode: 404})

        if(reviewsData?.data?.route) {
          result.items[0] = {
            ...result.items[0],
            ...reviewsData.data.route
          }
        }

        if(labelsData?.data?.products?.items) {
          result.items[0] = {
            ...result.items[0],
            ...reviewsData.data.route,
            ...labelsData.data.products.items[0]
          }
        }

        product.value = merge({}, product.value, result.items[0] as Product || null);
      } else {
        const [productData, reviewsData, labelsData] = await Promise.all([
          //@ts-ignore
          $vsf.$magento.api.productDetailsOutOfStock(route.value.fullPath),
          //@ts-ignore
          $vsf.$magento.api.getProductDetailsReviews({
            productId: routeData.id,
            url: route.value.fullPath
          }),
          //@ts-ignore
          $vsf.$magento.api.getProductLabelsOutOfStock(route.value.fullPath),
        ]);

        const {data} = productData;

        if (!data || !data?.route?.id) nuxtError({statusCode: 404});

        if(reviewsData?.data?.route) {
          data.route = {
            ...data.route,
            ...reviewsData.data.route
          }
        }

        if(labelsData?.data?.route) {
          data.route = {
            ...data.route,
            ...reviewsData.data.route,
            ...labelsData.data.route
          }
        }

        product.value = merge({}, product.value, data.route as Product || null);
      }

      breadcrumbs.value = getBreadcrumbsArray();
    };

    const fetchProductExtendedData = async (searchQuery = getBaseSearchQuery()) => {
      const {data} = await query<ProductDetailsQuery>(getProductPriceBySkuGql, searchQuery);

      product.value = merge({}, product.value, data.products?.items?.[0] as Product);
    };

    const fetchProductExtendedDataRelated = async () => {
      const baseSearchQueryRelated = {
        filter: {
          sku: {
            eq: routeData.sku,
          },
        },
      };

      const [related_products, upsell_products, bestSellers] = await Promise.all([
        <RelatedProductQuery>search(baseSearchQueryRelated),
        <RelatedProductQuery>searchUpsell(baseSearchQueryRelated),
        <RelatedProductQuery>searchBestSellers(baseSearchQueryRelated),
      ])

      product.value = merge({}, product.value, {related_products, upsell_products, bestSellers});
    };

    const {
      loadBlocks,
    } = useContent();

    useFetch(async () => {
      try {
        const promises = [
          loadBlocks({identifiers: staticBlocksIds}),
        ];
        //@ts-ignore
        const loadedData = await Promise.all(promises);

        //@ts-ignore
        staticBlocks.value = loadedData[0];

        loaded.value = true;

        await fetchProductBaseData();

        if (Boolean(product?.value?.sku) === false) nuxtError({statusCode: 404});

        await Promise.all([fetchProductExtendedData(), loadWishlist(), fetchProductExtendedDataRelated()]);

        const productTags = [
          {
            prefix: 'redis_cache_prefix',
            value: getCachePrefix()
          }
        ];

        addTags(productTags);
      } catch (e) {
        nuxtError({statusCode: 404})
      }
    });

    const staticBlocksIds = [
      'newsletter_block',
    ]

    const getStaticBlockById = (id) => {
      return cmsGetters.getStaticBlock(staticBlocks.value, id);
    }

    const getCachePrefix = () => {
      let prefix = '';

      if(routeData?.id) {
        prefix += `CAT_P_${routeData.id};`;

        if(routeData?.__typename === 'ConfigurableProduct') {
          prefix += `CONFIGURABLE_${routeData.id}`;
        }
      }

      return prefix;
    }

    onMounted(() => {
      if (breadcrumbs.value?.[0]) {
        let path = breadcrumbs.value[0].link;

        if (path[path.length - 1] === '/') {
          path = path.slice(0, -1);
        }
        if (path[0] === '/') {
          path = path.substring(1);
        }

        setActiveCategory({
          link: path
        });
      }
    })

    return {
      getStaticBlockById,
      staticBlocks,
      isGiftStore,
      routeData,
      loading,
      product,
      fetchProduct: fetchProductExtendedData,
      breadcrumbs,
      addProductToCart,
      isProductInCart,
      cartError,
      canAddToCart,
      isCartLoading,
      loadingRelated,
      addOrRemoveItem,
      isInWishlist,
      isAuthenticated,
      loadingUpsell,
      loadingBestSellers
    };
  },
  head() {
    return getMetaInfo(this.routeData);
  }
});
