var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-overview",attrs:{"data-sku":_vm.routeData.sku}},[(_vm.product)?_c('div',{staticClass:"product"},[_c('div',{key:_vm.product.id,staticClass:"product-content"},[_c('UniversalProduct',{attrs:{"product":_vm.product,"is-fetching":_vm.loading,"breadcrumbs":_vm.breadcrumbs,"add-product-to-cart":_vm.addProductToCart,"is-product-in-cart":_vm.isProductInCart,"is-cart-loading":_vm.isCartLoading,"can-add-to-cart":_vm.canAddToCart,"cart-error":_vm.cartError,"add-or-remove-item-from-wishlist":_vm.addOrRemoveItem,"is-authenticated":_vm.isAuthenticated,"is-product-in-wishlist":_vm.isInWishlist,"route-data":_vm.routeData,"has-related":!!(!_vm.loadingRelated && _vm.product['related_products']) || (_vm.isGiftStore && !_vm.loadingBestSellers && _vm.product['bestSellers']),"related-products":_vm.isGiftStore ? _vm.product['bestSellers'] : _vm.product['related_products'],"related-add-product-to-cart":_vm.addProductToCart,"related-is-product-in-cart":_vm.isProductInCart,"related-can-add-product-to-cart":_vm.canAddToCart},on:{"fetchProduct":function($event){return _vm.fetchProduct($event.query)}}}),_vm._v(" "),(!_vm.loadingUpsell && _vm.product['upsell_products'] && !_vm.isGiftStore)?_c('div',{staticClass:"related-products bestsellers container"},[_c('client-only',[_c('LoadWhenVisible',[_c('RelatedProducts',{attrs:{"center":true,"title":_vm.$t('Verwandte Artikel'),"gap":30,"per-view":1.4,"breakpoints":{
                  768: {
                    slidesPerView: 4
                  }
                },"add-product-to-cart":_vm.addProductToCart,"is-product-in-cart":_vm.isProductInCart,"products":_vm.product['upsell_products'],"can-add-product-to-cart":_vm.canAddToCart,"is-product-in-wishlist":_vm.isInWishlist,"is-authenticated":_vm.isAuthenticated,"add-or-remove-item-from-wishlist":_vm.addOrRemoveItem}})],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.product['bestSellers'] && !_vm.loadingBestSellers && !_vm.isGiftStore)?_c('div',{staticClass:"related-products bestsellers container"},[_c('client-only',[_c('LoadWhenVisible',[_c('RelatedProducts',{attrs:{"center":true,"title":_vm.$t('Andere Kunden kauften auch'),"gap":30,"per-view":1.4,"breakpoints":{
                  768: {
                    slidesPerView: 4
                  }
                },"add-product-to-cart":_vm.addProductToCart,"is-product-in-cart":_vm.isProductInCart,"products":_vm.product['bestSellers'],"can-add-product-to-cart":_vm.canAddToCart,"is-product-in-wishlist":_vm.isInWishlist,"is-authenticated":_vm.isAuthenticated,"add-or-remove-item-from-wishlist":_vm.addOrRemoveItem}})],1)],1)],1):_vm._e()],1),_vm._v(" "),_c('section',{staticClass:"content-section"},[_c('LoadWhenVisible',[(_vm.getStaticBlockById('newsletter_block'))?_c('StaticBlock',{staticClass:"info-menu",attrs:{"block":_vm.getStaticBlockById('newsletter_block')}}):_vm._e()],1)],1)]):_c('div',[_c('ProductSkeleton')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }