


























































import {
  defineComponent, ref, useContext, useRouter,
} from '@nuxtjs/composition-api';
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import {email, required} from "vee-validate/dist/rules";
import Input from '~/components/Palmers/Input/Input.vue';
import {SubscriptionStatusesEnum, useNewsletter} from '~/composables';
import PictureItem from "~/components/Palmers/BaseComponents/Picture/PictureItem.vue";

export default defineComponent({
  name: 'Newsletter',
  components: {
    ValidationProvider,
    ValidationObserver,
    Input,
    PictureItem
  },
  props: {
    element: {
      type: Object,
      require: true
    },
    source: {
      type: String,
      default() {
        return 'default'
      }
    },
    icon: {
      type: String,
      require: true
    },
  },
  data() {
    return {
      subscribed: <SubscriptionStatusesEnum | Boolean>false,
      subscribedError: <Object | Boolean>false
    }
  },
  setup(props) {
    //@ts-ignore
    const router = useRouter();
    const {$vsf, $config} = useContext();
    const {updateSubscription, error} = useNewsletter();

    const form = ref({
      email: null
    });

    return {
      router,
      magentoBaseUrl: $vsf.$magento.config.magentoBaseUrl,
      magentoMediaUrl: $config.magentoMediaUrl,
      form,
      updateSubscription,
      error: error.value
    }
  },
  methods: {
    getSource() {
      if (this.source === 'default') {
        return this.magentoBaseUrl;
      } else {
        return this.magentoMediaUrl;
      }
    },

    async subscribe() {
      // const subscribe = await this.updateSubscription(this.form);
      await this.$router.push({path: '/newsletter/subscribe', query: {email: this.form.email}});
      // if (subscribe) {
      //
      //   this.subscribed = subscribe;
      // } else {
      //   this.subscribedError = this.error;
      // }
    }
  },
  mounted() {
    extend('email', {
      ...email,
      message: 'Invalid email',
    });

    extend('required', {
      ...required,
      message: 'This field is required',
    });
  }
});
