










import {
  defineComponent,
} from '@nuxtjs/composition-api';

import {usePageStore} from "~/stores/page";
import {getMetaInfo} from "~/helpers/getMetaInfo";

import {useCache} from "@vue-storefront/cache";
import {useGtm, useMenu, usePageBuilder} from "~/composables/Palmers";
import PageBuilder from "~/components/Palmers/PageBuilder/PageBuilder.vue";
import { onSSR } from '@vue-storefront/core';
export default defineComponent({
  name: 'CategoryPage',
  components: {
    PageBuilder
  },
  setup() {
    const {addTags} = useCache();
    const {isPageBuilder} = usePageBuilder();
    const {setActiveCategory} = useMenu();
    const {view} = useGtm();

    const {routeData} = usePageStore();

    if(process.client) {
      setActiveCategory(routeData);
    }

    onSSR(() => {
      const categoryTags = [{
        prefix: `redis_cache_prefix`,
        value: `CAT_C_${routeData?.id}`,
      }];

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      addTags(categoryTags);
    });

    return {
      isPageBuilder,
      routeData,
      view
    }
  },
  mounted() {
    //@ts-ignore
    this.view();
  },
  head() {
    return getMetaInfo(this.routeData);
  }
})
