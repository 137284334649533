import type {
  CmsBlock
} from '~/modules/GraphQL/types';

export interface CmsGetters {
  getStaticBlock: (blocks: CmsBlock[], id: String) => CmsBlock|Boolean;
  [getterName: string]: any;
}

export const getStaticBlock = (blocks: CmsBlock[], id: String): CmsBlock|Boolean => {
  if(!blocks || !blocks.length) return false;

  const filterBlocks = blocks.filter((block) => block && block.identifier === id);
  if(filterBlocks.length) {
    return filterBlocks[0];
  }
  return false;
};


const cmsGetters: CmsGetters = {
  getStaticBlock
};

export default cmsGetters;
