























import {SfHeading} from '@storefront-ui/vue';
import {
  defineComponent, onMounted,
  ref, useFetch, useRoute,
} from '@nuxtjs/composition-api';
import {useCache} from '@vue-storefront/cache';
import {getMetaInfo} from '~/helpers/getMetaInfo';
import type {CmsPage} from '~/modules/GraphQL/types';
import {usePageStore} from '~/stores/page';
import HTMLContent from '~/components/HTMLContent.vue';
import {useGtm, useMenu, usePageBuilder} from "~/composables/Palmers";
import PageBuilder from "~/components/Palmers/PageBuilder/PageBuilder.vue";

export default defineComponent({
  name: 'CmsPage',
  components: {
    HTMLContent,
    SfHeading,
    PageBuilder
  },
  setup() {
    const { view } = useGtm();
    const route = useRoute();
    const {isPageBuilder} = usePageBuilder();
    const {routeData} = usePageStore();
    const {addTags} = useCache();
    const {setActiveCategory} = useMenu();

    useFetch(() => {
      let path = route.value.fullPath;

      if(path[path.length - 1] === '/') {
        path = path.slice(0, -1);
      }

      if(path[0] === '/') {
        path = path.substring(1);
      }

      if(!path) {
        path = '/';
      }

      setActiveCategory({
        link: path
      })

      //TODO: Temporary fix, ignoring no-route page ( a bug with out-of-stock items occurs ). PAL-2008

      if(routeData?.identifier !== 'no-route'){
        addTags([{prefix: 'redis_cache_prefix', value: `CMS_P_${routeData?.id || routeData?.page_id}`}]);
      }
    })

    const page = ref<CmsPage | null>(routeData);

    return {
      page,
      isPageBuilder,
      view
    };
  },
  head() {
    return getMetaInfo(this.page);
  },
  mounted() {
    //@ts-ignore
    this.view()

    this.$nextTick(() => {
      setTimeout(() => {
        if(window?.['OneTrust']) {
          window['OneTrust'].OnConsentChanged(() => {
            document.body.dispatchEvent(new Event('hydrate'))
          });
          window['OneTrust'].initializeCookiePolicyHtml();
          document.getElementById('ot-sdk-btn')?.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            window['OneTrust'].ToggleInfoDisplay();
          });
        }
      }, 200)
    })
  }
});
