

















import {
  defineComponent,
  useContext,
  useRoute
} from '@nuxtjs/composition-api';
import {usePageStore} from '~/stores/page';
import CATEGORY from '~/pages/_Palmers/Category.vue';
import SUB_CATEGORY from '~/pages/_Palmers/SubCategory.vue';
import CMS_PAGE from '~/pages/_Palmers/Cms.vue';
import PRODUCT from '~/pages/_Palmers/Product.vue';
import BLOG_POST from '~/pages/_Palmers/PalmersStorySingle.vue';
import useSeo from "~/composables/Palmers/useSeo";
import {Logger} from "~/helpers/logger";
import {RoutableInterface} from "~/modules/GraphQL/types";
import {MetaInfo} from "vue-meta";

export default defineComponent({
  name: 'PageResolver',
  components: {
    SUB_CATEGORY,
    CATEGORY,
    CMS_PAGE,
    PRODUCT,
    BLOG_POST,
    LoaderFrame: () => import("~/components/Palmers/BaseComponents/Loaders/LoaderFrame.vue")
  },
  data() {
    return {
      routeData: null,
      type: null,
      loading: null,
    }
  },
  setup() {
    const pageStore = usePageStore();
    const context = useContext();
    const route = useRoute();

    const {getSeoData} = useSeo();

    const fetchUrl = async (noRoute = false) => {
      const {path} = route.value;

      const clearUrl = path.replace(/[a-z]+\/[cp|]\//gi, '').replace(`/${context.i18n.locale}`, '');

      Logger.debug('middleware/url-resolver', clearUrl);

      const urlFixer = (url) => {
        if (url[0] === '/') {
          url = url.substring(1);
        }

        if (url[url.length - 1] === '/') {
          url = url.slice(0, -1);
        }

        return url;
      }

      const currentUrl = (clearUrl === '/' || clearUrl === '') ? '/' : urlFixer(clearUrl);

      const {
        data,
        errors
        //@ts-ignore
      } = await context.app.$vsf.$magento.api.urlResolver({
        url: noRoute ? '/no-route' : currentUrl,
        updated: ''
      }, {urlResolver: 'urlResolver'});

      Logger.debug('middleware/url-resolver/result', {data, errors});

      const results: RoutableInterface | null = (data?.['route'] || data?.['amBlogPost']) || null;

      if (!results || errors?.length) return await fetchUrl(true);

      if (!results?.type && results?.['post_id']) {
        // @ts-ignore
        results.type = 'BLOG_POST';
      } else if (!results?.type) {
        return await fetchUrl(true);
      }

      if (results?.['status'] === 2) {
        return await fetchUrl(true);
      }

      if (results?.type === 'CATEGORY') {
        if (
          (
            results?.['display_mode'] === 'PAGE_BUILDER_CONTENT_AND_PRODUCTS'
            || results['level'] > 2
            || !results['description']
            || results?.['display_mode'] === 'PRODUCTS'
          )
          && results?.['display_mode'] !== 'PAGE_BUILDER_CONTENT'
        ) {
          // @ts-ignore
          results.type = 'SUB_CATEGORY';
        }
      }

      pageStore.$patch((state) => {
        state.routeData = results;
      });

      return results;
    }

    const redirectUser = (path, code) => {
      context.redirect(code, path);
    }

    return {
      getSeoData,
      fetchUrl,
      redirectUser
    };
  },
  async fetch() {
    this.loading = true;
    //@ts-ignore
    this.routeData = await this.fetchUrl();

    if (!this.routeData || !this.routeData?.['type']) {
      this.$nuxt.context.error({
        statusCode: 404
      })
    }

    if (this.routeData?.['redirect_code'] !== 0 && this.routeData?.['relative_url']) {
      //@ts-ignore
      this.redirectUser(`/${this.routeData['relative_url']}`, this.routeData['redirect_code']);
    }

    this.type = this.routeData?.['type'];
    this.loading = false;
  },
  fetchOnServer: true,
  head() {
    return {
      title: this.loading ? this.$t('Loading...') : this.$t('404 Page not found'),
      //@ts-ignore
      ...this.getSeoData(this.routeData)
    } as MetaInfo;
  },
});
