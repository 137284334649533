import {ref, useContext, readonly} from '@nuxtjs/composition-api';
import {Logger} from '~/helpers/logger';
import type {
  UpsellProducts,
  UseUpsellProductsError,
  UseUpsellProductsInterface,
  UseUpsellProductsSearchParams,
} from '~/modules/catalog/product/composables/useUpsellProducts/useUpsellProducts';

/**
 * Allows searching for upsell products
 * with params for sort, filter and pagination.
 *
 * See the {@link UseUpsellProductsInterface} for a list of methods and values available in this composable.
 */
export function useBestSellesProducts(): UseUpsellProductsInterface {
  const {app} = useContext();
  const loading = ref(false);
  const error = ref<UseUpsellProductsError>({
    search: null,
  });

  const search = async (params: UseUpsellProductsSearchParams): Promise<UpsellProducts[]> => {
    const {customQuery, ...searchParams} = params;

    let results = null;

    try {
      loading.value = true;

      Logger.debug('[Magento] Load upsell products based on ', {searchParams});


      function mergeObjects(obj1, obj2, obj3) {
        if ((obj1.items.length !== obj2.items.length) !== (obj1.items.length !== obj3.items.length)) {
          throw new Error('Arrays must have the same length to merge.');
        }

        return {
          ...obj1,
          items: obj1.items.map((item, index) => ({
            ...item,
            ...obj2.items[index],
            ...obj3.items[index]
          }))
        };
      }

      const [productRelatedData, relatedLabelsData, relatedReviewsData] = await Promise.all([
        //@ts-ignore
        app.$vsf.$magento.api.loadBestSellersData(),
        //@ts-ignore
        app.$vsf.$magento.api.loadBestSellersLabels(),
        //@ts-ignore
        app.$vsf.$magento.api.loadBestSellersReviews()
      ]);

      const {data} = productRelatedData;

      Logger.debug('[Result] Best sellers products:', {data});


      const products = productRelatedData?.data?.['bestSellers'] && relatedLabelsData?.data?.['bestSellers'] && relatedReviewsData?.data?.['bestSellers']
        ? mergeObjects(productRelatedData.data['bestSellers'], relatedLabelsData.data['bestSellers'], relatedReviewsData.data['bestSellers']) : [];

      let beforeResult = [];

      for (let p = 0; p < products.items.length; p++) {
        if (beforeResult.filter((product) => product?.uid === products.items[p]).length !== 0) continue;
        beforeResult.push(products.items[p]);
      }

      results = beforeResult;

      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useUpsellProducts/search', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  return {
    search,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export default useBestSellesProducts;
