export default `
query amBlogPost($id: Int) {
   amBlogPost(
      id: $id
      ) {
         list_thumbnail
         short_content
         author_id
   }
}
`;
